import { Account as AccountAPI } from '@air/api';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';

import { callOnFullAccountUserPool } from '~/utils/callOnFullAccountUserPool';
import { configureAPIPackage, configureAPIPackageForAnonymousSignUp } from '~/utils/ConfigureAmplify';
import { getActiveUserSessionType } from '~/utils/getActiveUserSessionType';

export const NO_CURRENT_USER_ERROR = 'No current user';

export const ACCOUNT = 'ACCOUNT';

export const getAccountKey = () => {
  return [ACCOUNT];
};

export const useAccount = () => {
  const { isLoggedIn } = useIsLoggedIn();

  return useQuery(getAccountKey(), () => callOnFullAccountUserPool(AccountAPI.get), {
    staleTime: Infinity,
    enabled: isLoggedIn,
  });
};

export const usePublicAccount = () => {
  return useQuery(
    getAccountKey(),
    async () => {
      const existingAccountType = await getActiveUserSessionType();
      if (existingAccountType === 'none') {
        return null;
      } else if (existingAccountType === 'full') {
        configureAPIPackage();
        return AccountAPI.get();
      } else if (existingAccountType === 'anonymous') {
        configureAPIPackageForAnonymousSignUp();
        return AccountAPI.get();
      }
    },
    {
      staleTime: Infinity,
    },
  );
};
