import { AirActionTrackingLocation } from '@air/analytics';
import { JustifiedBox } from '@air/component-gallery-view';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { GetItemMenuOptions } from '~/constants/MenuOptions';

export type GetSelectionMenuOptionsType = (
  trackLocation: AirActionTrackingLocation,
  isDark?: boolean,
) => DropdownMenuOption[];

export interface ItemProps {
  /** Callback that is passed the item when clicked */
  onItemClick: (trackLocation: AirActionTrackingLocation) => void;
  onItemCmdClick?: (trackLocation: AirActionTrackingLocation) => void;

  /** Callback that is passed when custom fields are clicked */
  onCustomFieldsClick?: () => void;
  /** A boolean determining if the item is selectable */
  isSelectable: boolean;
  /** Callback that is passed the item to get back the submenu options that show in the ellipsis menu */
  getItemMenuOptions: GetItemMenuOptions;
  /** Callback that returns options to show when item is selected */
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
}

export interface AssetItemProps extends ItemProps {
  /** Callback that is passed when comments are clicked */
  onCommentsClick?: () => void;
  /** Callback that is passed when versions are clicked */
  onVersionsClick?: () => void;
}

export interface FileItemProps extends ItemProps {}

export enum GalleryItemType {
  board = 'board',
  asset = 'asset',
  file = 'file',
  loader = 'loader',
  upload = 'upload',
  header = 'header',
}

export const selectableTypes = [GalleryItemType.asset, GalleryItemType.board, GalleryItemType.file];

export interface GalleryViewRender<T extends any> {
  index: number;
  box: JustifiedBox;
  data: T;
}
