import { Account as TAccount } from '@air/api/types';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';

import { useInitializeAnalyticsTools } from '~/hooks/useInitializeAnalyticsTools';
import { useAccount, usePublicAccount } from '~/swr-hooks/account/useAccount';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface AccountProviderProps extends PropsWithChildren<{}> {}

export interface AccountProviderContextValue {
  data: TAccount | undefined;
  error?: unknown;
}

const defaultValue: AccountProviderContextValue = {
  data: undefined,
};

const AccountProviderContext = createContext<AccountProviderContextValue>(defaultValue);

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { data: account, error } = useAccount();
  const { initializeAnalyticsTools } = useInitializeAnalyticsTools();

  const value = useMemo(() => ({ data: account, error }), [account, error]);

  useEffect(() => {
    if (account) {
      initializeAnalyticsTools(account);
    }
  }, [account, initializeAnalyticsTools]);

  return <AccountProviderContext.Provider value={value}>{children}</AccountProviderContext.Provider>;
};

export const PublicAccountProvider = ({ children }: AccountProviderProps) => {
  const { data: account, error } = usePublicAccount();
  const { initializeAnalyticsTools } = useInitializeAnalyticsTools();

  /**
   * usePublicAccount may return:
   * - account: when user is logged in
   * - null: when user is not logged in
   * - undefined: when there was an error during getting current account
   * But all our components expects account to of type Account | undefined
   * to simplify code, if it is null, we will convert it to undefined
   */
  const value = useMemo(() => ({ data: account ?? undefined, error }), [account, error]);

  useEffect(() => {
    if (account) {
      initializeAnalyticsTools(account);
    }
  }, [account, initializeAnalyticsTools]);

  return <AccountProviderContext.Provider value={value}>{children}</AccountProviderContext.Provider>;
};

export const useAccountContext = () => {
  const context = useContext(AccountProviderContext);

  if (context === defaultValue) {
    const error = 'AccountProviderContext used outside of AccountProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
