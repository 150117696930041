import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { SelectableItem } from '@air/redux-selected-items';

import { GalleryItemType } from '~/components/Gallery/types';

export interface BaseSelectableGalleryItem<Type extends GalleryItemType> extends SelectableItem {
  type: Type;
}

export interface SelectableGalleryAssetItem extends BaseSelectableGalleryItem<GalleryItemType.asset> {
  item: Clip | PublicClip;
}

export interface SelectableGalleryFileItem extends BaseSelectableGalleryItem<GalleryItemType.file> {
  item: Clip | PublicClip;
}

export interface SelectableGalleryBoardItem<B> extends BaseSelectableGalleryItem<GalleryItemType.board> {
  item: B;
}

export type SelectableGalleryItem =
  | SelectableGalleryAssetItem
  | SelectableGalleryBoardItem<Board | PublicBoard>
  | SelectableGalleryFileItem;

export const isSelectableGalleryItem = (item: SelectableGalleryItem | SelectableItem): item is SelectableGalleryItem =>
  item.type === GalleryItemType.asset || item.type === GalleryItemType.board || item.type === GalleryItemType.file;

export type ClipAndAssetId = Pick<Clip, 'id' | 'assetId'>;
